import './ContactDetail.scss';
import Email from './Email';
import Phone from './Phone';

const ContactDetail = () => {
  return (
    <div className="contact-detail">
      <Phone />
      <Email />
    </div>
  );
};

export default ContactDetail;

import Project from '../components/projects/model/project';
import projectImg_gag from '../assets/images/projects/proj_GagarinT3.png';
import projectImg_Mz from '../assets/images/projects/proj_monZ1.png';
import projectImg_My from '../assets/images/projects/proj_monY1.png';
import projectImg_PC from '../assets/images/projects/proj_GagarinT2.png';

export const projects = [
  new Project('gagarin_smart_tshirt', projectImg_gag),
  new Project('physiological_monitor_z', projectImg_Mz),
  new Project('physiological_monitor_y', projectImg_My),
  new Project('projects_for_customers', projectImg_PC),
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import P_1 from '../../../assets/images/services/sEmS_1.png';
import P_2 from '../../../assets/images/services/sEmS_2.png';
import './BIOService.scss';

const bullets = Array.from({ length: 7 }, (_, i) => 'numbering_1.' + (i + 1));
const bullets_2 = Array.from({ length: 4 }, (_, i) => 'numbering_2.' + (i + 1));
const bullets_3 = Array.from({ length: 7 }, (_, i) => 'numbering_3.' + (i + 1));

const ESService: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'services.embedded_solution.detail',
  });

  return (
    <section className="embedded_solution">
      <p className="p16">{t('first_text')}</p>
      <ol className="service-bullets">
        {bullets.map((value) => (
          <li key={value} className="p16">
            {t([value])}
          </li>
        ))}
      </ol>
      <div className="grid-2">
        <img className="grid-img" src={P_1} alt="P_1" />
        <p className="grid-text p16">{t('second_text')}</p>
      </div>
      <div className="grid-2">
        <p className="grid-text p16">{t('third_text')}</p>
        <img className="grid-img" src={P_2} alt="P_2" />
      </div>
      <ol start={3}>
        <li>{t('fourth_text')}</li>
        <li>{t('fifth_text')}</li>
        <li>{t('sixth_text')}</li>
        <li>{t('seventh_text')}</li>
      </ol>
      <p className="p16">{t('fourth_text')}</p>
      <p className="p16">{t('fifth_text')}</p>
      <p className="p16">{t('sixth_text')}</p>
      <p className="p16">{t('seventh_text')}</p>
      <div className="service-bullets">
        {bullets_2.map((value) => (
          <li key={value} className="p16">
            {t([value])}
          </li>
        ))}
      </div>
      <p className="p16">{t('eight_text')}</p>
      <p className="p16">{t('ninth_text')}</p>
      <p className="p16">{t('tenth_text')}</p>
      <div className="service-bullets">
        {bullets_3.map((value) => (
          <li key={value} className="p16">
            {t([value])}
          </li>
        ))}
      </div>
    </section>
  );
};

export default ESService;

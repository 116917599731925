import React from 'react';
import P_1 from '../../assets/images/shop/mining_rig_1.png';
import ContactUs from '../UI/ContactUs';
import './Shop.scss';

const Shop: React.FC = (props) => {
  return (
    <>
      <section className="services square-after">
        <div className="container">
          <p className="shop-title thick  p18">
            Mining rig 6x RX 5700 – RX 5700XT – 330 Mh/s
          </p>
          <p className="shop-title p18">8, 759.00 EUR bez DPH</p>

          <div className="grid-container">
            <section className="biophysically_data_processing">
              <div className="grid-2">
                <img className="grid-img" src={P_1} alt="P_1" />
                <p className="shop-left  p18">
                  6x RX 5700XT – Sapphire – nejlepšia karta RX 5700. Výkon
                  naozaj vysoký: <b>330 MH/s </b>na ETH – 750 EUR za mesiac
                </p>
              </div>
              <p className="shop-title_2 shop-left">
                Parametry a špecifikácia:
              </p>
              <br />
              <p className="shop-left thick p18">Zdroj:</p>
              <p className="shop-left p16">
                1x : 1800w Mining Power Supply GPU ETH Rig Ethereum Tool
              </p>

              <br />
              <p className="shop-left thick p18">Procesor:</p>
              <p className="shop-left  p16">AMD Ryzen 3 1200</p>

              <br />
              <p className="shop-left thick p18">Základná doska:</p>
              <p className="shop-left  p16">MSI B450 GAMING PLUS MAX</p>

              <br />
              <p className="shop-left thick p18">Operačná pamäť:</p>
              <p className="shop-left  p16">
                Certifikovaná Gaming pamäť 1x8GB DDR4 3200MHz
              </p>
              <br />

              <p className="shop-left thick p18">Grafická karta:</p>

              <p className="shop-left  p16">
                Radeon RX 5700 XT 8176 MB · Sapphire
              </p>
              <p className="shop-left  p16">
                Radeon RX 5700 XT 8176 MB · Sapphire
              </p>
              <p className="shop-left  p16">
                Radeon RX 5700 XT 8176 MB · Sapphire
              </p>
              <p className="shop-left  p16">
                Radeon RX 5700 XT 8176 MB · Sapphire
              </p>
              <p className="shop-left  p16">
                Radeon RX 5700 XT 8176 MB · Sapphire
              </p>
              <p className="shop-left  p16">
                Radeon RX 5700 XT 8176 MB · Sapphire
              </p>

              <br />
              <p className="shop-left thick p18">Mining konštrukcia:</p>

              <p className="shop-left  p16">Pevná mining skriňa na 6 kariet</p>

              <br />
              <p className="shop-left  p16">
                <b>PCI-Express riser:</b> 6x
              </p>
              <p className="shop-left  p16">
                <b>Výkon:</b> 330 Mh/s (potenciál do 360Mh/s)
              </p>
              <p className="shop-left  p16">
                <b>Životnosť:</b> 7-9 rokov.
              </p>
              <p className="shop-left  p16">
                <b>Spotreba zo zásuvky:</b> 799w
              </p>

              <br />
              <p className="shop-left  thick p16">
                Aktuálne výpočet na 10.09.2021.
              </p>

              <br />
              <p className="shop-left  p16">Výnos:</p>
              <p className="shop-left  p16">$28.60 za deň</p>
              <p className="shop-left  p16">$857 za mesiac</p>
              <p className="shop-left  p16">$10284 za rok</p>
              <br />
              <p className="shop-left  p16">
                Aktuálny výnos si môžete overiť tu:
                <a href="https://www.cryptocompare.com/mining/calculator/eth?HashingPower=330&HashingUnit=MH%2Fs&PowerConsumption=790&CostPerkWh=0.1&MiningPoolFee=1">
                  {' '}
                  Kalkulačka výnosu těžby Etherea.
                </a>
              </p>

              <br />
              <p className="shop-left thick p16">
                Na aktuálny výnos má vplyv hlavne:
              </p>

              <li className="shop-left  p16">
                kurz Etherea k Americkému dolaru / Euru.
              </li>
              <li className="shop-left  p16">
                výkon všetkých ťažiacich rigov na svete ťažiacich Ethereum v
                daný moment – level náročnosti
              </li>
              <li className="shop-left  p16">vaša cena elektrickej energie</li>
              <li className="shop-left  p16">
                množstvo poplatkov zaplatených za tranzit na sieti: poplatky se
                spalujú a rozdelujú minerom-vám.
              </li>

              <br />
              <p className="shop-left  p16">
                Na místě nastavíme peněženku. HiveOs nebo RaveOs popřípadně Váš
                OS.
              </p>

              <p className="shop-left  p16">
                Začínajícímu těžaři pomůžeme se založením účtu, založením
                peněženky a ukážeme obsluhu a stroj v běhu. (vše je připraveno,
                nastaveno pro bezproblémovou funkčnost).
              </p>

              <p className="shop-left  p16">
                Všechny karty mají vyladěný overclock ve stylu zlatý řez mezi
                spotřeba / výkon / termální profil.
              </p>

              <br />
              <p className="shop-left thick p16">Použití</p>

              <li className="shop-left  p16">
                Plug & play – zapojíte do elektrické sítě, připojíte síťový
                kabel a funguje.
              </li>
              <li className="shop-left  p16">
                Nutné jenom prvotní nastaveni přes prohlížeč počítače, anebo
                přes telefon. S tým Vám pomůžeme.
              </li>
              <li className="shop-left  p16">
                Pohodlný monitoring přes telefon a počítač.
              </li>

              <br />
              <p className="shop-left thick p16">Objednanie rigu:</p>

              <li className="shop-left  p16">
                Pre objednanie napíšte email na info@keysoft.sk
              </li>
              <li className="shop-left  p16">Rigi máme skladom.</li>
              <li className="shop-left  p16">
                Rigi si môžte vyzdvihnúť osobne, alebo Vám ich pošleme.
              </li>
            </section>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default Shop;

import React from 'react';
import { useTranslation } from 'react-i18next';
import P_1 from '../../../assets/images/services/sSWA_1.png';
import P_2 from '../../../assets/images/services/sSWA_2.png';
import './BIOService.scss';

const bullets_1 = Array.from({ length: 8 }, (_, i) => 'numbering_1.' + (i + 1));

const SWAService: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'services.system_analysis.detail',
  });

  return (
    <section className="system_analysis">
      <p className="p16">{t('first_text')}</p>
      <ol className="service-bullets">
        {bullets_1.map((value) => (
          <li key={value} className="p16">
            {t([value])}
          </li>
        ))}
      </ol>
      <div className="grid-2">
        <img className="grid-img" src={P_1} alt="P_1" />
        <p className="grid-text p16">
          {t('second_text')}
          <ol className="service-bullets">
            {bullets_1.map((value) => (
              <li key={value} className="p16">
                {t([value])}
              </li>
            ))}
          </ol>
        </p>
      </div>
      <div className="grid-2">
        <p className="grid-text p16">{t('third_text')}</p>
        <img className="grid-img" src={P_2} alt="P_2" />
      </div>
      <p className="p16">{t('fourth_text')}</p>
    </section>
  );
};

export default SWAService;

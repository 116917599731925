import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as CheckMark } from '../../assets/icons/check-circle.svg';
import ButtonDefault from '../UI/buttons/ButtonDefault';
import Service from './model/service';
import './ServiceCard.scss';

const ServiceCard: React.FC<{
  service: Service;
  moreAboutService: string;
  className: string;
}> = (props) => {
  const history = useHistory();
  const { t } = useTranslation('translation', {
    keyPrefix: 'services.' + props.service.loc_key,
  });

  const serviceLink = '/services/' + props.service.loc_key;

  const navigate = (): void => history.push(serviceLink);

  const bullets = Array.from({ length: props.service.numOfBullets }, (_, i) =>
    (i + 1).toString()
  );

  const classes = 'service-card ' + props.className;

  return (
    <NavLink className={classes} to={serviceLink}>
      {React.createElement(props.service.icon)}
      <h5 className="service-card-title">{t('title')}</h5>
      <p className="service-card-info p16">{t('info')}</p>
      <div className="service-bullets">
        {bullets.map((value) => (
          <div key={value} className="service-bullet">
            <CheckMark />
            <span key={value} className="p16">
              {t([value])}
            </span>
          </div>
        ))}
      </div>
      <ButtonDefault type="after" onClick={navigate}>
        {props.moreAboutService}
      </ButtonDefault>
    </NavLink>
  );
};

export default ServiceCard;

import GoogleMap from './GoogleMap';
import './MapCard.scss';

const MapCard = () => {
  return (
    <div className="map-card">
      <GoogleMap />
      <div className="map-card-address">
        <p className="street button-font">HubHub Twin City C</p>
        <p className="street button-font">Mlynské nivy 16</p>
        <p className="city button-font">811 09 Bratislava</p>
        <p className="state button-font">Slovakia</p>
      </div>
    </div>
  );
};

export default MapCard;

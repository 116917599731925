import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const defaultLang = (): string => {
  let lang = 'sk';
  if (document !== null) {
    const htmlElement = document?.querySelector('html');
    lang = htmlElement ? htmlElement.lang : lang;
  }
  return lang;
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: defaultLang(),
    fallbackLng: 'sk',
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React from 'react';
import { useTranslation } from 'react-i18next';
import P_1 from '../../../assets/images/projects/proj_monZ1.png';
import P_2 from '../../../assets/images/projects/proj_monZ2.png';
import './PhysiologicalMonitorZ.scss';

const PhysiologicalMonitorZ: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.physiological_monitor_z.detail',
  });

  return (
    <section className="biophysically_data_processing">
      <div className="grid-2">
        <img className="grid-img" src={P_1} alt="P_1" />
        <p className="grid-text p16">{t('first_text')}</p>
      </div>
      <div className="grid-2">
        <p className="grid-text p16">{t('second_text')}</p>
        <img className="grid-img" src={P_2} alt="P_2" />
      </div>      
      
      <p className="grid-text p16">{t('third_text')}</p>
    </section>
  );
};


export default PhysiologicalMonitorZ;



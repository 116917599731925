import { ReactComponent as SystemAnalysis } from '../assets/icons/services/product-analysis.svg';
import { ReactComponent as MobileWebApp } from '../assets/icons/services/app.svg';
import { ReactComponent as BiophysicallDataProcessing } from '../assets/icons/services/biophysicall-data-processing.svg';
import { ReactComponent as EmbeddedSolutions } from '../assets/icons/services/embedded-solutions.svg';
import { ReactComponent as VisualizationOfPrototypes } from '../assets/icons/services/visualization-of-prototypes.svg';
import { ReactComponent as IoT } from '../assets/icons/services/sensor-data.svg';
import { ReactComponent as FirmwareDevelopment } from '../assets/icons/services/prorotype-workshop.svg';
import Service from '../components/services/model/service';

export const services = [
  new Service('iot', IoT, 3),
  new Service('embedded_solution', EmbeddedSolutions, 3),
  new Service('firmware_development', FirmwareDevelopment, 3),
  new Service('mobile_web_app', MobileWebApp, 3),
  new Service('visualization_of_prototypes', VisualizationOfPrototypes, 3),
  new Service('system_analysis', SystemAnalysis, 3),
  new Service('biophysically_data_processing', BiophysicallDataProcessing, 3),
];

import './Hamburger.scss';

const Hamburger: React.FC<{ opened: boolean; toogle: () => void }> = (
  props
) => {
  return (
    <div
      className={`navbar-toggler ${props.opened ? 'open' : ''}`}
      onClick={props.toogle}>
      <div className="hamburger"></div>
    </div>
  );
};

export default Hamburger;

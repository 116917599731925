import React from 'react';
import { useTranslation } from 'react-i18next';
import FW_1 from '../../../assets/images/services/sFW_1.png';
import FW_2 from '../../../assets/images/services/sFW_2.png';
import './FWService.scss';

const bullets_1 = Array.from({ length: 4 }, (_, i) => 'numbering_1.' + (i + 1));

const FWService: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'services.firmware_development.detail',
  });

  return (
    <section className="firmware_development">
      <p className="p16 centered-text">{t('first_text')}</p>
      <div className="grid-2">
        <img className="grid-img" src={FW_1} alt="FW_1" />
        <p className="grid-text p16">{t('second_text')}</p>
      </div>
      <div className="grid-2">
        <p className="grid-text p16">{t('third_text')}</p>
        <img className="grid-img" src={FW_2} alt="FW_2" />
      </div>
      <div className="service-bullets">
        {bullets_1.map((value) => (
          <li key={value} className="p16">
            {t([value])}
          </li>
        ))}
      </div>
    </section>
  );
};

export default FWService;

import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Project from './model/project';
import './ProjectCard.scss';
import ButtonDefault from '../UI/buttons/ButtonDefault';

const ProjectCard: React.FC<{
  project: Project;
  className: string;
}> = (props) => {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'projects' });

  const projectLink = '/projects/' + props.project.loc_key;
  const navigate = (): void => history.push(projectLink);

  const title = t([props.project.loc_key] + '.title');
  const classes = 'project-card ' + props.className;

  return (
    <NavLink className={classes} to={projectLink}>
      <img className="project-card-img" src={props.project.image} alt={title} />
      <h5 className="project-card-title card-header">{title}</h5>
      <ButtonDefault type="after" onClick={navigate}>
        {t('more_about_project')}
      </ButtonDefault>
    </NavLink>
  );
};

export default ProjectCard;

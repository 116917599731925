import { useTranslation } from 'react-i18next';
import { services } from '../../store/services';
import ContactUs from '../UI/ContactUs';
import ServiceCard from './ServiceCard';
import './Services.scss';

const Services = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'services' });
  const moreAboutService = t('more_about_service');

  const serviceCardClassName = (index: number): string =>
    (index <= 1 ? 'first-row ' : '') + (index % 2 === 0 ? 'even' : 'odd');

  return (
    <>
      <section className="services square-after">
        <div className="container">
          <h1 className="services-title">Služby</h1>
          <p className="services-info p18">{t('info')}</p>
          <div className="grid-container">
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                service={service}
                moreAboutService={moreAboutService}
                className={serviceCardClassName(index)}
              />
            ))}
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default Services;

import { useTranslation } from 'react-i18next';
import Email from '../contacts/components/Email';
import Phone from '../contacts/components/Phone';
import './ContactUs.scss';

const ContactUs = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'contact' });

  return (
    <section className="contact-us container">
      <h2 className="h2-w">{t('contact_us')}</h2>
      <div className="contact-us-details">
        <Phone />
        <span> / </span>
        <Email />
      </div>
    </section>
  );
};

export default ContactUs;

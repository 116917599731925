import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ButtonRegularAfter from '../../UI/buttons/ButtonRegularAfter';
import './Info.scss';

const Info = () => {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  const navigate_shop = (): void => history.push('/shop');

  return (
    <section className="info container">
      <h2 className="info-title color-white">{t('info')}</h2>
      <ButtonRegularAfter onClick={navigate_shop}>
        {t('shop')}
      </ButtonRegularAfter>
    </section>
  );
};

export default Info;

import './Phone.scss';

const Phone = () => {
  return (
    <a href="callto:+421948403784" className="contact-phone">
      +421 948 403 784
    </a>
  );
};

export default Phone;

import React from 'react';
import { useTranslation } from 'react-i18next';
import iotFirst from '../../../assets/images/services/iot-first.png';
import iotSecond from '../../../assets/images/services/iot-second.png';
import './IoTService.scss';

const IoTService: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'services.iot.detail',
  });

  return (
    <section className="iot-service">
      <p className="p16 centered-text">{t('first_text')}</p>
      <div className="grid-2">
        <img className="grid-img" src={iotFirst} alt="iot first" />
        <p className="grid-text p16">{t('second_text')}</p>
      </div>
      <div className="grid-2">
        <p className="grid-text p16">{t('third_text')}</p>
        <img className="grid-img" src={iotSecond} alt="iot second" />
      </div>
      <p className="p16 centered-text">{t('fourth_text')}</p>
    </section>
  );
};

export default IoTService;

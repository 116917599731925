import './Contacts.scss';
import ContactInfo from './components/ContactInfo';
import MapCard from './components/MapCard';
import ContactDetail from './components/ContactDetail';

const Contacts = () => {
  return (
    <section className="contacts container">
      <ContactInfo />
      <MapCard />
      <ContactDetail />
    </section>
  );
};

export default Contacts;

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './HeaderLinks.scss';

const links = ['home', 'services', 'projects', 'shop', 'contact'];

const HeaderLinks: React.FC<{ onNavigate: () => void }> = (props) => {
  const location = useLocation();
  const { t } = useTranslation('translation', { keyPrefix: 'header' });

  const getNavLinkClass = (path: string) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <>
      {links.map((link) => (
        <li
          key={link}
          className={`nav-link ${
            link === 'contact' ? 'contact-nav-link' : ''
          } ${getNavLinkClass('/' + link)}`}>
          <NavLink
            exact={link === 'home'}
            activeClassName="active"
            to={link === 'home' ? '/' : `/${link}`}
            onClick={props.onNavigate}>
            {t([link])}
          </NavLink>
        </li>
      ))}
    </>
  );
};

export default HeaderLinks;

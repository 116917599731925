import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './LocaleSelector.scss';

const LocaleSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('sk');

  const localize = (lang: string): void => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
  };

  return (
    <li className={'nav-link locale-selector'}>
      <span
        className={selectedLanguage === 'sk' ? 'selected-language' : ''}
        onClick={localize.bind(null, 'sk')}>
        sk
      </span>
      <span className={'language-divider'}> / </span>
      <span
        className={selectedLanguage === 'en' ? 'selected-language' : ''}
        onClick={localize.bind(null, 'en')}>
        en
      </span>
    </li>
  );
};

export default LocaleSelector;

import React from 'react';
import { useTranslation } from 'react-i18next';
import P_1 from '../../../assets/images/projects/proj_monY1.png';
import './PhysiologicalMonitorY.scss';

const PhysiologicalMonitorY: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.physiological_monitor_y.detail',
  });

  return (
    <section className="biophysically_data_processing">
      <div className="grid-2">
        <img className="grid-img" src={P_1} alt="P_1" />
        <p className="grid-text p16">{t('first_text')}</p>
      </div>      
      <p className="grid-text p16">{t('second_text')}</p>
    </section>
  );
};


export default PhysiologicalMonitorY;



import { useTranslation } from 'react-i18next';
import langerImg from '../../../../assets/images/team/langer.png';
import habekImg from '../../../../assets/images/team/habek.png';
import binasImg from '../../../../assets/images/team/binas.png';
import repcikImg from '../../../../assets/images/team/repcik.png';
import horvathImg from '../../../../assets/images/team/horvath.png';
import kubackaImg from '../../../../assets/images/team/kubacka.png';
import levakImg from '../../../../assets/images/team/levak.png';
import TeamMember from '../../models/team_member';
import './HomeTeam.scss';
import TeamMemberCard from './TeamMemberCard';

const teamMembers = [
  new TeamMember('Peter Langer', 'langer_info', langerImg),
  new TeamMember('Jakub Hábek', 'habek_info', habekImg),
  new TeamMember('Michal Binas', 'binas_info', binasImg),
  new TeamMember('Juraj Repčík', 'repcik_info', repcikImg),
  new TeamMember('Martin Horváth', 'horvath_info', horvathImg),
  new TeamMember('Michal Kubačka', 'kubacka_info', kubackaImg),
  new TeamMember('Ján Levák', 'levak_info', levakImg),
];

const HomeTeam = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'home.home_team',
  });

  const teamInfo = t('team_info');

  return (
    <section className="home-team">
      <div className="container">
        <h2 className="our-team section-header color-white">{t('our_team')}</h2>
        <div className="grid-container">
          {teamMembers.map((member, index) => (
            <TeamMemberCard key={index} member={member} />
          ))}
          <div className="team-info-grid button-font">{teamInfo}</div>
        </div>
        <div className="team-info button-font">{teamInfo}</div>
      </div>
    </section>
  );
};

export default HomeTeam;

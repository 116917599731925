import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo-keysoft.svg';
import Divider from '../UI/Divider';
import Hamburger from './Hamburger';
import './Header.scss';
import HeaderLinks from './HeaderLinks';
import LocaleSelector from './LocaleSelector';

const Header = () => {
  const [isOpen, toogleIsOpen] = useState(false);

  const toogleMenu = (): void => {
    toogleIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const onNavigate = () => {
    if (isOpen) {
      toogleMenu();
    }
  };

  const navContent = (
    <>
      <HeaderLinks onNavigate={onNavigate} />
      <LocaleSelector />
    </>
  );

  return (
    <header className={`header ${isOpen ? 'opened' : ''}`}>
      <nav className="header-navbar container">
        <NavLink to="/">
          <img className="navbar-logo" src={logo} alt="KeySoft" />
        </NavLink>
        <Hamburger opened={isOpen} toogle={toogleMenu} />
        <ul className="navbar-nav">{navContent}</ul>
      </nav>
      <Divider />
    </header>
  );
};

export default Header;

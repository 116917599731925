import React from 'react';
import { useTranslation } from 'react-i18next';
import P_1 from '../../../assets/images/projects/proj_GagarinT1.png';
import P_2 from '../../../assets/images/projects/proj_GagarinT2.png';
import P_3 from '../../../assets/images/projects/proj_GagarinT3.png';
import P_4 from '../../../assets/images/projects/proj_GagarinT4.png';
import './GagarinSmartTShirt.scss';

const GagarinSmartTShirt: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.gagarin_smart_tshirt.detail',
  });

  return (
    <section className="biophysically_data_processing">
      <div className="grid-2">
        <img className="grid-img" src={P_1} alt="P_1" />
        <p className="grid-text p16">{t('first_text')}</p>
      </div>
      <div className="grid-2">
        <p className="grid-text p16">{t('second_text')}</p>
        <img className="grid-img" src={P_2} alt="P_2" />
      </div>      
      <div className="grid-2">
        <img className="grid-img" src={P_3} alt="P_3" />
        <p className="grid-text p16">{t('third_text')}</p>
      </div>  
      <div className="grid-2">
        <p className="grid-text p16">{t('fourth_text')}</p>
        <img className="grid-img" src={P_4} alt="P_4" />
      </div>  

    </section>
  );
};


export default GagarinSmartTShirt;




import React from 'react';
import Reference from '../../models/reference';
import './HomeReferenceCard.scss';

const HomeReferenceCard: React.FC<{ reference: Reference }> = (props) => {
  return (
    <div className="home-reference-card">
      <q className="home-reference-card-quote p16">{props.reference.quote}</q>
      <h5 className="home-reference-card-author">{props.reference.author}</h5>
      <p className="home-reference-card-company p14">
        {props.reference.company}
      </p>
    </div>
  );
};

export default HomeReferenceCard;

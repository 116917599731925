import Footer from '../footer/Footer';
import Header from '../header/Header';

const Layout: React.FC = (props) => {
  return (
    <>
      <Header />
      <main className="main">{props.children}</main>
      <Footer />
    </>
  );
};

export default Layout;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import ButtonDefault from '../UI/buttons/ButtonDefault';
import ContactUs from '../UI/ContactUs';
import IoTService from './components/IoTService';
import BIOService from './components/BIOService';
import ESService from './components/ESService';
import GraphService from './components/GraphService';
import MWDService from './components/MWDService';
import FWService from './components/FWService';
import SWAService from './components/SWAService';
import './ServiceDetail.scss';

const serivceComponents = new Map([['iot', IoTService]
,['firmware_development', FWService]
,['mobile_web_app', MWDService]
,['visualization_of_prototypes', GraphService]
,['system_analysis', SWAService]
,['biophysically_data_processing', BIOService]
,['embedded_solution', ESService]
 ]);

const ServiceDetail = () => {
  const params = useParams<{ serviceId: string }>();
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'services' });
  const navigate = (): void => history.push('/services');

  const rc = serivceComponents.get(params.serviceId);
  if (!rc) {
    navigate();
    return <div>Error</div>;
  }
  const component = React.createElement(rc);

  return (
    <>
      <section className="service-detail square-after">
        <div className="container">
          <ButtonDefault type="before" onClick={navigate}>
            {t('back_to_all_services')}
          </ButtonDefault>
          <h1 className="service-detail-title">
            {t([params.serviceId + '.title'])}
          </h1>
          {component}
          <ButtonDefault type="before" onClick={navigate}>
            {t('back_to_all_services')}
          </ButtonDefault>
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default ServiceDetail;

import { useTranslation } from 'react-i18next';
import { services } from '../../../../store/services';
import HomeServiceCard from './HomeServiceCard';
import './HomeServices.scss';

const HomeServices = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  return (
    <section className="home-services square-before square-after">
      <div className="container">
        <h2 className="our-services section-header">{t('our_services')}</h2>
        {services.map((service, index) => (
          <HomeServiceCard key={index} service={service} />
        ))}
      </div>
    </section>
  );
};

export default HomeServices;

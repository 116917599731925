import Contacts from '../contacts/Contacts';
import HomeCustomers from './components/customers/HomeCustomers';
import Info from './components/Info';
import HomeProjects from './components/projects/HomeProjects';
import HomeReferences from './components/references/HomeReferences';
import HomeServices from './components/services/HomeServices';
import HomeTeam from './components/team/HomeTeam';

const Home = () => {
  return (
    <>
      <Info />
      <HomeServices />
      <HomeProjects />
      <HomeCustomers />
      <HomeTeam />
      <HomeReferences />
      <Contacts />
    </>
  );
};

export default Home;

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { projects } from '../../../../store/projects';
import ProjectCard from '../../../projects/ProjectCard';
import ButtonOutline from '../../../UI/buttons/ButtonOutline';
import './HomeProjects.scss';

const HomeProjects = () => {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'projects' });

  const navigateToProjects = (): void => history.push('/projects');

  const visibleProjects = projects.length > 3 ? projects.slice(0, 3) : projects;

  return (
    <section className="home-projects">
      <div className="container">
        <h2 className="successful-projects section-header color-white">
          {t('successful_projects')}
        </h2>
        <div className="grid-container">
          {visibleProjects.map((project, index) => (
            <ProjectCard key={index} project={project} className="bg-dark" />
          ))}
        </div>
        <ButtonOutline after={true} onClick={navigateToProjects}>
          {t('all_projects')}
        </ButtonOutline>
      </div>
    </section>
  );
};

export default HomeProjects;

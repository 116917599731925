import './ButtonOutline.scss';
import { ReactComponent as After } from '../../../assets/icons/arrow-right.svg';
import React from 'react';

export const ButtonOutline: React.FC<{
  after?: boolean;
  onClick: React.MouseEventHandler;
}> = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={`btn-outline ${props.after ? 'btn-outline-after' : ''}`}
      type="button">
      {props.children}
      {props.after && <After />}
    </button>
  );
};

export default ButtonOutline;

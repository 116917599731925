import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonOutlineArrow from '../../../UI/buttons/ButtonOutlineArrow';
import Reference from '../../models/reference';
import HomeReferenceCard from './HomeReferenceCard';
import './HomeReferences.scss';

const references = ['bozoky', 'kust', 'pertot'];

const HomeReferences = () => {
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'home.home_references',
  });

  useEffect(() => {
    setIsMobileState();
    window.addEventListener('resize', setIsMobileState);
    return () => window.removeEventListener('resize', setIsMobileState);
  }, []);

  const setIsMobileState = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const navigateRight = (): void => {
    setIndex((prevIndex) => {
      const step = isMobile ? 1 : 2;
      const newIndex = prevIndex + step;
      return newIndex >= references.length ? 0 : newIndex;
    });
  };

  const navigateLeft = (): void => {
    setIndex((prevIndex) => {
      const step = isMobile ? 1 : 2;
      const newIndex = prevIndex - step;
      return newIndex < 0 ? references.length - step : newIndex;
    });
  };

  const getReference = (paIndex: number): Reference | null => {
    let ref = null;
    if (references.length > index) {
      const r = references[paIndex];
      ref = new Reference(t(r + '.quote'), t(r + '.author'), t(r + '.company'));
    }
    return ref;
  };

  const firstReference = getReference(index);
  const secondReference = getReference((index + 1) % references.length);

  return (
    <section className="home-references square-after-right">
      <div className="container">
        <h1 className="references references-header">{t('references')}</h1>
        {firstReference && <HomeReferenceCard reference={firstReference} />}
        {secondReference && !isMobile && (
          <HomeReferenceCard reference={secondReference} />
        )}
        <div className="refrences-buttons">
          <ButtonOutlineArrow right={false} onClick={navigateLeft} />
          <ButtonOutlineArrow right={true} onClick={navigateRight} />
        </div>
      </div>
    </section>
  );
};

export default HomeReferences;

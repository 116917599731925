import './Email.scss';

const Email = () => {
  return (
    <a href="mailto:info@keysoft.sk" className="contact-email">
      info@keysoft.sk
    </a>
  );
};

export default Email;

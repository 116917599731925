import { useTranslation } from 'react-i18next';
import { projects } from '../../store/projects';
import ContactUs from '../UI/ContactUs';
import ProjectCard from './ProjectCard';
import './Projects.scss';

const Projects = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'projects' });
  return (
    <>
      <section className="projects square-after">
        <div className="container">
          <h1 className="projects-title">{t('title')}</h1>
          <p className="projects-info p18">{t('info')}</p>
          <div className="grid-container">
            {projects.map((project, index) => (
              <ProjectCard key={index} project={project} className="bg-light" />
            ))}
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default Projects;

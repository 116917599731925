import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Service from '../../../services/model/service';
import './HomeServiceCard.scss';

const HomeServiceCard: React.FC<{ service: Service }> = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'services.' + props.service.loc_key,
  });

  return (
    <NavLink
      className="home-service-card"
      to={'/services/' + props.service.loc_key}>
      <h5 className="home-service-card-title card-header">{t('title')}</h5>
      {React.createElement(props.service.icon)}
    </NavLink>
  );
};

export default HomeServiceCard;

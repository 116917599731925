import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { projects } from '../../store/projects';
import ButtonDefault from '../UI/buttons/ButtonDefault';
import ContactUs from '../UI/ContactUs';
import GagarinSmartTShirt from './components/GagarinSmartTShirt';
import PhysiologicalMonitorY from './components/PhysiologicalMonitorY';
import PhysiologicalMonitorZ from './components/PhysiologicalMonitorZ';
import ProjectsForCustomers from './components/ProjectsForCustomers';
import './ProjectDetail.scss';

const projectComponents = new Map([
  ['projects_for_customers', ProjectsForCustomers],
  ['gagarin_smart_tshirt', GagarinSmartTShirt],
  ['physiological_monitor_z', PhysiologicalMonitorZ],
  ['physiological_monitor_y', PhysiologicalMonitorY],
]);

const ProjectDetail = () => {
  const params = useParams<{ projectId: string }>();
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'projects' });
  const navigate = (): void => history.push('/projects');

  const project = projects.find((p) => p.loc_key === params.projectId);
  const rc = projectComponents.get(params.projectId);
  if (!project || !rc) {
    navigate();
    return <div>Error</div>;
  }
  const component = React.createElement(rc);

  return (
    <>
      <section className="project-detail square-after">
        <div className="container">
          <ButtonDefault type="before" onClick={navigate}>
            {t('back_to_all_projects')}
          </ButtonDefault>
          {/* <img
            className="project-detail-img"
            src={project?.image}
            alt={t([params.projectId] + '.title')}
          /> */}
          <h1 className="project-detail-title">{t([params.projectId] + '.title')}</h1>
          {component}
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default ProjectDetail;

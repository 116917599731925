import './ButtonDefault.scss';
import { ReactComponent as After } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as Before } from '../../../assets/icons/arrow-left.svg';

export const ButtonDefault: React.FC<{
  type?: 'after' | 'before';
  onClick: React.MouseEventHandler;
}> = (props) => {
  const classes = 'button-font btn-default ' + props.type;
  return (
    <button onClick={props.onClick} className={classes} type="button">
      {props.type === 'before' && <Before />}
      {props.children}
      {props.type === 'after' && <After />}
    </button>
  );
};

export default ButtonDefault;

import { useTranslation } from 'react-i18next';
import { ReactComponent as Honeywell } from '../../../../assets/icons/customers/honeywell.svg';
import { ReactComponent as NCR } from '../../../../assets/icons/customers/ncr.svg';
import { ReactComponent as BBB } from '../../../../assets/icons/customers/bbb.svg';
import BikeFinder from '../../../../assets/icons/customers/BikeFinder';
import Aliter from '../../../../assets/icons/customers/Aliter';
import './HomeCustomers.scss';

const HomeCustomers = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'home.home_customers',
  });

  return (
    <section className="home-customers">
      <div className="container">
        <span className="p18 our-customers">{t('our_customers')}</span>
        <Honeywell />
        <NCR />
        <BBB />
        <BikeFinder />
        <Aliter />
      </div>
    </section>
  );
};

export default HomeCustomers;

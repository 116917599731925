import { useTranslation } from 'react-i18next';
import './ContactInfo.scss';

const ContactInfo = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'contact' });

  return (
    <div className="contact-info">
      <p className="contact-title p16-w">{t('title')}</p>
      <h2 className="connect_with_us section-header color-white">
        {t('connect_with_us')}
      </h2>
      <p className="contact-text button-font">{t('text')}</p>
    </div>
  );
};

export default ContactInfo;

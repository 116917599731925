import { Route, Switch } from 'react-router-dom';

import './App.scss';
import Home from './components/home/Home';
import Services from './components/services/Services';
import Shop from './components/shop/Shop';
import Projects from './components/projects/Projects';
import Contacts from './components/contacts/Contacts';
import Layout from './components/layout/Layout';
import ProjectDetail from './components/projects/ProjectDetail';
import ServiceDetail from './components/services/ServiceDetail';

const App = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/services" exact>
          <Services />
        </Route>
        <Route path="/services/:serviceId">
          <ServiceDetail />
        </Route>
        <Route path="/projects" exact>
          <Projects />
        </Route>
        <Route path="/projects/:projectId">
          <ProjectDetail />
        </Route>
        <Route path="/shop">
          <Shop />
        </Route>
        <Route path="/contact">
          <Contacts />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;

import React from 'react';
import { useTranslation } from 'react-i18next';
import './TeamMemberCard.scss';
import TeamMember from '../../models/team_member';

const TeamMemberCard: React.FC<{ member: TeamMember }> = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'home.home_team',
  });

  const name = props.member.name;

  return (
    <div className="team-member-card color-white">
      <img
        className="team-member-card-img"
        src={props.member.image}
        alt={name}
      />
      <p className="team-member-card-title">{name}</p>
      <p className="team-member-card-info">{t([props.member.info_key])}</p>
    </div>
  );
};

export default TeamMemberCard;

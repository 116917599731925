import './ButtonRegularAfter.scss';
import './ButtonRegular.scss';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right-line.svg';

export const ButtonRegularAfter: React.FC<{
  onClick: React.MouseEventHandler;
}> = (props) => {
  return (
    <button
      onClick={props.onClick}
      className="btn-regular btn-regular-after"
      type="button">
      {props.children}
      <ArrowRight />
    </button>
  );
};

export default ButtonRegularAfter;

import './ButtonOutlineArrow.scss';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right-line.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left-line.svg';
import React from 'react';

export const ButtonOutlineArrow: React.FC<{
  right: boolean;
  onClick: React.MouseEventHandler;
}> = (props) => {
  return (
    <button onClick={props.onClick} className="btn-outline-arrow" type="button">
      {props.right ? <ArrowRight /> : <ArrowLeft />}
    </button>
  );
};

export default ButtonOutlineArrow;

import './GoogleMap.scss';

const GoogleMap = () => {
  const src =
    'https://www.google.com/maps/embed/v1/place?q=HubHub+Twin+City+C++Mlynské+nivy+16++811+09+Bratislava&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8';
  return (
    <iframe
      className="map"
      src={src}
      loading="lazy"
      title="companyAddress"></iframe>
  );
};

export default GoogleMap;

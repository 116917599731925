import Divider from '../UI/Divider';
import './Footer.scss';

const Footer = () => {
  return (
    <>
      <footer>
        <Divider />
        <div className="container copyright">
          Copyright © 2021 KeySoft. All rights reserved.
        </div>
      </footer>
    </>
  );
};

export default Footer;

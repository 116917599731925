import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProjectsForCustomers.scss';

const bullets = Array.from({ length: 14 }, (_, i) => 'numbering_1.' + (i + 1));

const ProjectsForCustomers: React.FC = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'projects.projects_for_customers.detail',
  });

  return (
    <section className="biophysically_data_processing">
      <p className="p16">{t('first_text')}</p>
      <div className="service-bullets">
        {bullets.map((value) => (
          <li key={value} className="p16">
            {t([value])}
          </li>
        ))}
      </div>
    </section>
  );
};

export default ProjectsForCustomers;
